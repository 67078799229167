import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export const FetchIntercept = async () => {
  const { fetch: originalFetch } = window;
  const { instance, accounts } = useMsal();

  window.fetch = async (...args) => {
    let [url, config] = args;

    // request interceptor starts
    if (!url.startsWith('http')) {
      url = `${process.env.REACT_APP_BACKENDAPI_BASEURL}${url.startsWith('/') ? url : '/' + url}`

      config = config ?? {};
      config = {
        ...config, headers: {
          ...config?.headers ?? '', Authorization: `Bearer ${await (await instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] })).accessToken}`,
          Referer: window.location.href
        }
      };
    }
    // request interceptor ends

    const response = await originalFetch(url, config);

    // response interceptor here
    if (response.status >= 400)
      throw await response.json()

    return response;
  };
}
