import React, { useState } from "react";
import { TableHead, TableRow, TableCell, Tooltip, TableSortLabel } from "@material-ui/core";

const TableHeaderRow = ({ order, orderBy, onRequestSort, columns }) => {

    const createSortHandler = (property) => (event) => {
        onRequestSort(property);
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map(
                    column => (
                        <TableCell
                            key={column.id}
                            align={column.numeric ? 'right' : 'left'}
                            padding={column.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === column.id ? order : false}>
                            <Tooltip
                                title="Sort"
                                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}>
                                <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={order}
                                    onClick={createSortHandler(column.id)}>
                                    {column.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this,
                )}
            </TableRow>
        </TableHead>
    );
};

export default TableHeaderRow;