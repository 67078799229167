import React, { useState, useEffect } from 'react';
import { LinearProgress, TextField, Paper, Button, Table, TableBody, TableRow, TableCell, IconButton, TablePagination } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import TableHeaderRow from '../shared/tableHeaderRow';
import { AddApplicantDialog } from './addApplicantDialog';
import { ViewEditDialog } from './viewEditDialog';
import { SendEmailDialog } from './sendEmailDialog';

export const PrequalifyGrid = ({ }) => {

    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('fullname');
    const [sort, setSort] = useState('asc');
    const [data, setData] = useState({
        total: 0,
        prequalifySearch: []
    });
    const [addApplicantDialog, setAddApplicantDialog] = useState(false);
    const [viewEditDialog, setViewEditDialog] = useState(false);
    const [sendEmailDialog, setSendEmailDialog] = useState(false);
    const [prequalifyIdSelected, setPrequalifyIdSelected] = useState(0);


    useEffect(() => {
        return () => console.log('...unmounting');
    }, []);

    useEffect(() => {
        getPrequalifications();
    }, [searchText, page, perPage, sortBy, sort]);

    const getPrequalifications = async () => {

        try {

            setLoading(true);

            const response = await fetch(`/v1/Prequalify?searchText=${searchText}&page=${page + 1}&perPage=${perPage}&sortBy=${sortBy}&sort=sort`);

            setData(await response.json());

        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    };

    const addApplicantDialogClose = () => {
        getPrequalifications();
        setAddApplicantDialog(false);
    }

    const viewEditDialogClose = () => {
        getPrequalifications();
        setViewEditDialog(false);
    }

    const sendEmailDialogClose = () => {
        setSendEmailDialog(false);
    }

    const changeSort = (p) => {
        let sort = 'asc';
        if (sortBy === p && sort === 'asc') {
            sort = 'desc';
        }

        setSort(sort);
        setSortBy(p);
    }

    return (
        <div style={{ padding: '0 5% 0 5%' }}>
            <div >
                <Button style={{ margin: '30px 25px 0 0' }} variant='contained' color='primary' onClick={() => setAddApplicantDialog(true)}>ADD PREQUALIFICATION</Button>
                <TextField style={{ width: '80%' }} label='Enter Search Term' margin='normal' onKeyUp={e => setSearchText(e.target.value)} />
            </div>
            <Paper style={{ width: '100%', marginTop: '30px' }}>
                {loading && <LinearProgress />}
                <br />

                <div style={{ overflowX: 'auto' }}>
                    <Table style={{ minWidth: '900px' }} aria-labelledby='tableTitle'>
                        <TableHeaderRow
                            order={sort}
                            orderBy={sortBy}
                            onRequestSort={p => changeSort(p)}
                            rowCount={data.total}
                            columns={cols} />
                        <TableBody>
                            {data?.prequalifySearch?.map(n => {
                                return (
                                    <TableRow
                                        hover
                                        key={n.id}>
                                        <TableCell align='left' style={{ width: '15%' }}>{<span>{n.firstname}</span>}</TableCell>
                                        <TableCell align='left' style={{ width: '15%' }}>{<span>{n.lastname}</span>}</TableCell>
                                        <TableCell align='left' style={{ width: '20%' }}>{<span>{n.email}</span>}</TableCell>
                                        <TableCell align='left' style={{ width: '5%' }}>
                                            {
                                                n.submitComplete ? <DoneIcon /> : <CloseIcon />
                                            }
                                        </TableCell>
                                        <TableCell align='left' style={{ width: '5%' }}>
                                            {
                                                n.pdfSent ? <DoneIcon /> : <CloseIcon />
                                            }
                                        </TableCell>
                                        <TableCell align='left' style={{ width: '10%' }}>{<span>{n.created.split('T')[0]}</span>}</TableCell>
                                        <TableCell align='left' style={{ width: '10%' }}>{<span>{n.updated.split('T')[0]}</span>}</TableCell>
                                        <TableCell align='left' style={{ width: '25%' }}>
                                            {
                                                <div>
                                                    <Button style={{ margin: '5px' }} onClick={() => { setPrequalifyIdSelected(n.id); setViewEditDialog(true) }} variant='contained'>
                                                        View
                                                    </Button>
                                                    <Button style={{ margin: '5px' }} onClick={() => { setPrequalifyIdSelected(n.id); setSendEmailDialog(true) }} variant='contained' color='primary'>
                                                        Send
                                                    </Button>
                                                </div>
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component='div'
                    count={data.total}
                    rowsPerPage={perPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onPageChange={(e, p) => setPage(p)}
                    onRowsPerPageChange={(e) => setPerPage(e.target.value)} />
                {loading && <LinearProgress />}
                <AddApplicantDialog open={addApplicantDialog} onClose={addApplicantDialogClose} />
                <ViewEditDialog key={'v' + prequalifyIdSelected} open={viewEditDialog} onClose={viewEditDialogClose} prequalifyId={prequalifyIdSelected} />
                <SendEmailDialog key={'e' + prequalifyIdSelected} open={sendEmailDialog} onClose={sendEmailDialogClose} prequalifyId={prequalifyIdSelected} />
            </Paper>
        </div>
    );
}

export const cols = [
    { id: 'firstname', numeric: false, disablePadding: false, label: 'Firstname' },
    { id: 'lastname', numeric: false, disablePadding: false, label: 'Lastname' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'submitComplete', numeric: false, disablePadding: false, label: 'Submit Complete' },
    { id: 'pdfSent', numeric: false, disablePadding: false, label: 'Pdf Sent' },
    { id: 'created', numeric: false, disablePadding: false, label: 'Created' },
    { id: 'updated', numeric: false, disablePadding: false, label: 'Updated' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
];