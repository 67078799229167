import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Loans } from './loan/loans'
import { Loan } from './loan/loan'
import { Settings } from './setting/settings';
import { AppBar, Button, makeStyles, MenuItem, Tabs, Tab, Toolbar, Typography } from '@material-ui/core';
import { PrequalifyGrid } from './prequalify/prequalifyGrid';
import { useEffect } from 'react';
import LoginButton from './Authentication/loginButton';
import LogoutButton from './Authentication/logoutButton';
import { NonLogged } from './nonLogged/landing';
import { FetchIntercept } from './shared/fetchIntercept';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

export const App = () => {

  const { } = FetchIntercept();

  useEffect(async () => {

    return () => {
      console.log('...unmounting app');
    }
  }, [])

  return (
    <>
      <AuthenticatedTemplate className='body'>
        <Router>
          {/* <Auth0ProviderWithHistory> */}
          <AppBar position='static'>
            <Toolbar>
              <div className='nav-links'>
                {/* <Link to='/'>Home</Link>
              <Link to='/loan'>Loans</Link>
              <Link to='/setting'>Settings</Link> */}
                <Link to='/prequalify'>Prequalify</Link>
                <LogoutButton />
              </div>
            </Toolbar>
          </AppBar>
          <Routes>
            <Route path='/loan' element={<Loans />} />
            <Route path='/loan/:id' element={<Loan />} />
            <Route path='/setting' element={<Settings data='data' />} />
            <Route path='/prequalify' element={<PrequalifyGrid />} />
            <Route path='*' element={<PrequalifyGrid />} />
          </Routes>
          {/* <div className='footer'>Footer</div> */}
          {/* </Auth0ProviderWithHistory> */}
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AppBar position='static'>
          <Toolbar>
            <div className='nav-links'>
              <LoginButton />
            </div>
          </Toolbar>
        </AppBar>
        <NonLogged />
      </UnauthenticatedTemplate>
    </>
  )
};