import React from 'react';
import { Button } from '@material-ui/core';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const LogoutButton = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    return isAuthenticated && <Button variant='outlined' color='inherit' onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}>Log Out</Button>;
};

export default LogoutButton;