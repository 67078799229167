import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogActions, Button, LinearProgress, TextField, Input } from '@material-ui/core';
import Form from '@rjsf/material-ui';
import parse from 'html-react-parser';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DialogTitle } from '../shared/dialogTitle';
import { InfoRounded, Label } from '@material-ui/icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';

export const SendEmailDialog = ({ open, onClose, prequalifyId }) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isError, setIsError] = useState(false);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [emailTo, setEmailTo] = useState('');
    const [emailCc, setEmailCc] = useState('');
    const [emailFrom, setEmailFrom] = useState('');
    const [prequalifyFile, setPrequalifyFile] = useState({ filename: '', url: '', base64: '' });

    useEffect(async () => {
        if (prequalifyId !== 0)
            await getDefaultEmail();

        return () => {
            console.log('unmounting..');
        }
    }, []);

    const getDefaultEmail = async () => {
        setLoading(true);
        setDisabled(true);

        try {
            let f = getPrequalifyFile();

            let r = await (await fetch(`/v1/Prequalify/${prequalifyId}/emailTemplate`)).json();

            setEmailSubject(r.subject);
            setEmailBody(r.body);
            setEmailTo(r.to);
            setEmailFrom(r.from);

            await f;
        }
        catch (ex) {
        }

        setDisabled(false);
        setLoading(false);
    }

    const getPrequalifyFile = async () => {

        try {
            let r = await (await fetch(`/v1/Prequalify/${prequalifyId}/document`)).json();

            let f = await (await fetch(r.url, { headers: { 'Content-Type': 'application/octet-stream' } })).blob();
            let base64 = (await toBase64(f)).split('base64,')[1];

            setPrequalifyFile({ ...r, base64 });
        }
        catch (ex) {
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const sendEmail = async () => {
        setLoading(true);
        setDisabled(true);
        setIsError(false);

        prequalifyFile?.base64 ?? await getDefaultEmail();

        try {
            let res = await fetch(`/v1/email`, {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({
                    to: emailTo,
                    cc: emailCc,
                    from: emailFrom,
                    subject: emailSubject,
                    body: emailBody,
                    attachment: {
                        filename: prequalifyFile.filename,
                        fileContent: prequalifyFile.base64
                    }
                })
            });

            if (!res.ok)
                throw new Error();

            setEmailSent(true);
        }
        catch {
            setIsError(true);
        }

        setDisabled(false);
        setLoading(false);
    };

    const close = () => {
        setDisabled(false);
        setLoading(false);
        setEmailSent(false);
        onClose(false);
    };

    const modules = {
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
            ['link', 'image', 'video'],
            ['clean'],
        ]
    }

    const setBody = (v) => {
        v = v.replaceAll('<p>', '<div>');
        v = v.replaceAll('</p>', '</div>');
        setEmailBody(v);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={(event, reason) => { if (reason !== 'backdropClick') onClose(false) }} maxWidth='md'>
                {loading && <LinearProgress />}
                {!emailSent && <DialogTitle text={'Send to  ' + emailTo} onClose={() => onClose(false)} />}
                {emailSent && <DialogTitle text='Sent' onClose={() => onClose(false)} />}
                <DialogContent aria-disabled='disabled'>
                    {
                        emailSent && <div>Email has been successfully sent</div>
                    }
                    {
                        !emailSent &&
                        <>
                            <br />
                            <label class='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled'>Email Subject</label>
                            <Input id={'s' + prequalifyId} disabled={disabled} fullWidth={true} value={emailSubject} onChange={(event) => setEmailSubject(event.target.value)} />
                            <br /><br /><br />
                            <label class='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled'>Email Body</label><br />
                            <ReactQuill readOnly={disabled} modules={modules} value={emailBody} onChange={setBody} />
                            <br />
                            <label class='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled'>Optional CC (seperate multiple CC's by comma)</label>
                            <Input id={'cc' + prequalifyId} disabled={disabled} fullWidth={true} value={emailCc} onChange={(event) => setEmailCc(event.target.value)} />
                            <br /><br /><br />
                            <Button variant='outlined' onClick={() => window.open(prequalifyFile.url)}>
                                <AttachFileIcon />
                                {prequalifyFile.filename}
                            </Button>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    {isError && <div style={{ color: 'red' }}>Error sending email</div>}
                    {!emailSent && <Button onClick={sendEmail} color='primary' >Send</Button>}
                    {emailSent && <Button onClick={close} color='primary' >Close</Button>}
                </DialogActions>
                {loading && <LinearProgress />}
            </Dialog>
        </div >
    );
}