import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogActions, Button, LinearProgress } from '@material-ui/core';
import Form from '@rjsf/material-ui';
import { DialogTitle } from '../shared/dialogTitle';

export const ViewEditDialog = ({ open, onClose, prequalifyId }) => {

    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [data, setData] = useState({});
    const [pdfFile, setPdfFile] = useState({ filename: '', url: '' });

    useEffect(() => {
        if (prequalifyId !== 0)
            getData();
            
        return () => {
            console.log('unmounting..');
        }
    }, []);

    const getData = async () => {
        setLoading(true);

        try {
            let r = await (await fetch(`/v1/Prequalify/${prequalifyId}`)).json();

            setData(JSON.parse(r.jsonData));
        }
        catch (ex) {
        }

        setLoading(false);
    };

    const edit = () => {
        setIsEdit(true);
    };

    const save = async () => {
        setLoading(true);

        try {
            let r = await (await fetch(`/v1/Prequalify/${prequalifyId}`, {
                headers: { "Content-Type": "application/json" },
                method: "PUT",
                body: JSON.stringify(data)
            })).json();

            setData(JSON.parse(r.jsonData));
        }
        catch (ex) {
        }

        setLoading(false);
        setIsEdit(false);
    };

    const generatePdf = async () => {
        setLoading(true);

        try {
            let r = await(await fetch(`/v1/Prequalify/${prequalifyId}/document`)).json();

            setData(r);
            window.open(r.url);
        }
        catch (ex) {
        }

        setLoading(false);
    };

    // schema currently hard-coded but later will be returned by an API
    // so we can make it dynamic for different mortgage brokerages
    const schema = {
        title: '',
        type: 'object',
        required: ['intention', 'propertyType', 'propertyUse', 'purchaseTimeline', 'creditScoreRange', 'firstname', 'lastname', 'phone', 'email', 'monthlyIncome', 'monthlyExpenses'],
        definitions: {
            location: {
                type: 'object',
                required: ['city', 'state'],
                properties: {
                    address1: { type: 'string', title: 'Address 1', default: '' },
                    address2: { type: 'string', title: 'Address 2', default: '' },
                    city: { type: 'string', title: 'City', default: '' },
                    state: { type: 'string', title: 'State', default: '' },
                    zip: { type: 'string', title: 'Zip', default: '' }
                }
            }
        },
        properties: {
            submitComplete: { type: 'boolean', title: 'Submit complete', default: false },
            intention: { type: 'string', title: 'Request type', default: '', enum: ['Buy a house', 'Refinance', 'Sell a house'] },
            locationIdentified: { type: 'boolean', title: 'Location identified', default: false },
            propertyType: { type: 'string', title: 'Property type', enum: ['Single family', 'Townhouse', 'Condominium', 'Mobile/manufactured'] },
            propertyUse: { type: 'string', title: 'Property use', default: 'Primary residence', enum: ['Primary residence', 'Second home', 'Investment'] },
            purchaseTimeline: { type: 'string', title: 'Purchase timeline', enum: ['Signed a purchase agreement', 'Identified a house', 'Actively looking'] },
            firstTimeBuyer: { type: 'boolean', title: 'First time buyer', default: false },
            creditScoreRange: { type: 'integer', title: 'Credit score', minimum: 300, maximum: 850 },
            firstname: { type: 'string', title: 'Firstname', default: '' },
            lastname: { type: 'string', title: 'Lastname', default: '' },
            phone: { type: 'string', title: 'Phone', default: '' },
            email: { type: 'string', title: 'Email', default: '' },
            purchasePrice: { type: 'number', title: 'Purchase price' },
            monthlyIncome: { type: 'number', title: 'Monthly income' },
            downPayment: { type: 'number', title: 'Down payment' },
            monthlyExpenses: { type: 'number', title: 'Monthly expenses' }
        },
        allOf: [{
            if: {
                properties: { locationIdentified: { const: true } }
            },
            then: {
                properties: { locations: { type: 'array', title: '', description: 'Locations', items: { $ref: '#/definitions/location' } } }
            }
        }]
    };

    const uiSchema = {
        intention: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        locationIdentified: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        propertyType: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        propertyUse: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        purchaseTimeline: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        firstTimeBuyer: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        'ui:order': ['intention', 'locationIdentified', 'locations', 'propertyType', 'propertyUse', 'purchaseTimeline', 'firstTimeBuyer', 'creditScoreRange', 'firstname', 'lastname', 'phone', 'email', 'purchasePrice', 'monthlyIncome', 'downPayment', 'monthlyExpenses', 'submitComplete']
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={(event, reason) => { if (reason !== 'backdropClick') onClose(false) }} maxWidth='md'>
                {loading && <LinearProgress />}
                <DialogTitle text='Add Applicant' onClose={() => onClose(false)} />
                <DialogContent>
                    <Form readonly={!isEdit} uiSchema={uiSchema} schema={schema} formData={data} onChange={(d) => { setData(d.formData) }} >
                        {<div></div>}
                    </Form>
                </DialogContent>
                <DialogActions>
                    {!isEdit && !loading && <Button onClick={edit} color='primary'>Edit</Button>}
                    {isEdit && !loading && <Button onClick={save} color='primary'>Save</Button>}
                    {isEdit && !loading && <Button onClick={() => setIsEdit(false)} color='primary'>Cancel</Button>}
                    {!isEdit && !loading && <Button onClick={generatePdf} color='primary'>Generate Pdf</Button>}
                    {/* <Button onClick={() => onClose(false)} color='primary'>Close</Button> */}
                </DialogActions>
                {loading && <LinearProgress />}
            </Dialog>
        </div >
    );
};