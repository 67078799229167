import React from 'react';
import { Button } from '@material-ui/core';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

const LoginButton = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    return !isAuthenticated && <Button variant='outlined' color='inherit' onClick={() => instance.loginRedirect(loginRequest)}>Log In</Button>;
};

export default LoginButton;
