import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogActions, Button, LinearProgress } from '@material-ui/core';
import Form from '@rjsf/material-ui';
import { DialogTitle } from '../shared/dialogTitle';

export const AddApplicantDialog = ({ open, onClose }) => {

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        return () => {
            console.log('unmounting..');
        }
    }, []);

    const submitData = (data) => {
        setLoading(true);
        setDisabled(true);
        fetch(`/v1/prequalify`, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(data)
        }).then(r => {
            setLoading(false);
            setSubmitted(true);
        }).catch(e => {
            setLoading(false);
        });
    };

    // const a = {
    //     'submitComplete': false,
    //     'intention': '',
    //     'locationIdentified': true,
    //     'locations': [
    //         {
    //             'Address1': '',
    //             'Address2': '',
    //             'City': '',
    //             'State': '',
    //             'Zip': ''
    //         }
    //     ],
    //     'propertyType': '',
    //     'propertyUse': '',
    //     'purchaseTimeline': '',
    //     'firstTimeBuyer': true,
    //     'creditScoreRange': '',
    //     'firstname': '',
    //     'lastname': '',
    //     'phone': '',
    //     'email': '',
    //     'purchasePrice': '',
    //     'monthlyIncome': '',
    //     'downPayment': '',
    //     'monthlyExpenses': ''
    // }


    // schema currently hard-coded but later will be returned by an API
    // so we can make it dynamic for different mortgage brokerages
    const schema = {
        title: '',
        type: 'object',
        required: ['intention', 'propertyType', 'propertyUse', 'purchaseTimeline', 'creditScoreRange', 'firstname', 'lastname', 'phone', 'email', 'monthlyIncome', 'monthlyExpenses'],
        definitions: {
            location: {
                type: 'object',
                required: ['city', 'state'],
                properties: {
                    address1: { type: 'string', title: 'Address 1', default: '' },
                    address2: { type: 'string', title: 'Address 2', default: '' },
                    city: { type: 'string', title: 'City', default: '' },
                    state: { type: 'string', title: 'State', default: '' },
                    zip: { type: 'string', title: 'Zip', default: '' }
                }
            }
        },
        properties: {
            submitComplete: { type: 'boolean', title: 'Submit complete', default: false },
            intention: { type: 'string', title: 'Request type', default: '', enum: ['Buy a house', 'Refinance', 'Sell a house'] },
            locationIdentified: { type: 'boolean', title: 'Location identified', default: false },
            propertyType: { type: 'string', title: 'Property type', enum: ['Single family', 'Townhouse', 'Condominium', 'Mobile/manufactured'] },
            propertyUse: { type: 'string', title: 'Property use', default: 'Primary residence', enum: ['Primary residence', 'Second home', 'Investment'] },
            purchaseTimeline: { type: 'string', title: 'Purchase timeline', enum: ['Signed a purchase agreement', 'Identified a house', 'Actively looking'] },
            firstTimeBuyer: { type: 'boolean', title: 'First time buyer', default: false },
            creditScoreRange: { type: 'integer', title: 'Credit score', minimum: 300, maximum: 850 },
            firstname: { type: 'string', title: 'Firstname', default: '' },
            lastname: { type: 'string', title: 'Lastname', default: '' },
            phone: { type: 'string', title: 'Phone', default: '' },
            email: { type: 'string', title: 'Email', default: '' },
            purchasePrice: { type: 'number', title: 'Purchase price' },
            monthlyIncome: { type: 'number', title: 'Monthly income' },
            downPayment: { type: 'number', title: 'Down payment' },
            monthlyExpenses: { type: 'number', title: 'Monthly expenses' }
        },
        allOf: [{
            if: {
                properties: { locationIdentified: { const: true } }
            },
            then: {
                properties: { locations: { type: 'array', title: '', description: 'Locations', items: { $ref: '#/definitions/location' } } }
            }
        }]
    };

    const uiSchema = {
        intention: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        locationIdentified: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        propertyType: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        propertyUse: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        purchaseTimeline: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        firstTimeBuyer: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
        // creditScoreRange: { 'ui:widget': 'range' },
        'ui:order': ['intention', 'locationIdentified', 'locations', 'propertyType', 'propertyUse', 'purchaseTimeline', 'firstTimeBuyer', 'creditScoreRange', 'firstname', 'lastname', 'phone', 'email', 'purchasePrice', 'monthlyIncome', 'downPayment', 'monthlyExpenses', 'submitComplete']
        // 'ui:disabled': true
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={(event, reason) => { if (reason !== 'backdropClick') onClose(false) }} maxWidth='md'>
                {loading && <LinearProgress />}
                <DialogTitle text='Add Applicant' onClose={() => onClose(false)} />
                <DialogContent>
                    {
                        !submitted && <Form disabled={disabled} uiSchema={uiSchema} schema={schema} onChange={(d) => { console.log(d) }} onSubmit={(d) => submitData(d.formData)} onError={(e) => { console.log(e) }} />
                    }
                    {
                        submitted && <div>Successfully submitted</div>
                    }
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={() => onClose(false)} color='primary'>Close</Button> */}
                </DialogActions>
                {loading && <LinearProgress />}
            </Dialog>
        </div >
    );
};