import { GridClientSide, SortOrder } from '../shared/gridClientSide';

export const Loans = () => {
    console.log('loans');
    return (
        <>
            <div className='content'>Loans</div>
            {/* <GridClientSide defaultSortBy={'title'} defaultSortOrder={SortOrder.asc} /> */}
        </>
    );
};