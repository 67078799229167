import LoginButton from "../Authentication/loginButton"

export const NonLogged = () => {
    return (
        <div style={{ padding: '5% 5% 0 5%' }}>
            <label>Please login to continue</label>
            <br /><br />
            <LoginButton />
        </div>
    )
}