export const msalConfig = {
  auth: {
    clientId: 'c1ec7674-ff99-46c3-a0f8-161752af1493',
    authority: 'https://login.microsoftonline.com/f39d0fcf-42cf-4173-86ae-7b0dd9ec776f',
    redirectUri: process.env.REACT_APP_MSAL_CONFIG_REDIRECT_URL,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  }
}

export const loginRequest = {
  scopes: ['c1ec7674-ff99-46c3-a0f8-161752af1493/.default']
}